import React from 'react';
import { graphql } from 'gatsby';
import {
  Trans,
  I18nextContext,
  useTranslation,
} from 'gatsby-plugin-react-i18next';
import Container from '../components/container';
import GraphQLErrorList from '../components/graphql-error-list';
import SEO from '../components/seo';
import Layout from '../containers/layout';

export const query = graphql`
  query contactsPage($language: String!) {
    page: sanityPage(title: { en: { eq: "Contacts" } }) {
      title {
        bg
        en
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const ContactsHeader = () => (
  <div className="relative mt-24 max-w-md mx-auto px-4 sm:max-w-3xl sm:mt-32 sm:px-6 lg:max-w-7xl lg:px-8">
    <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">
      <Trans>Get in touch</Trans>
    </h1>
  </div>
);

const ContactsPage = (props) => {
  const { data, errors } = props;
  const { t } = useTranslation();
  const { language } = React.useContext(I18nextContext);
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  const contactDetails = [
    {
      name: t('Enquiries'),
      email: 'avangard_banov@mail.bg',
      telephone: '+359 42636720',
    },
    {
      name: t('Arch. Banko Banov'),
      email: 'avangard_banov@mail.bg',
      telephone: '+359 42636720',
    },
  ];
  const locations = [
    {
      city: t('Stara Zagora'),
      address: t(
        'Boulevard Slavianski 47, fl.2, office 18, 6002, Stara Zagora, Bulgaria'
      ),
    },
  ];
  return (
    <Layout hasCover headerChildren={<ContactsHeader />}>
      <>
        <SEO title={data?.page?.title[language]} />
        <Container>
          <div className="max-w-md mx-auto py-24 px-4 sm:max-w-3xl sm:py-32 sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="divide-y divide-warm-gray-200">
              <section
                className="lg:grid lg:grid-cols-3 lg:gap-8"
                aria-labelledby="contact-heading"
              >
                <h2
                  id="contact-heading"
                  className="text-2xl font-extrabold text-warm-gray-900 sm:text-3xl"
                >
                  <Trans>Get in touch</Trans>
                </h2>
                <div className="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:mt-0 lg:col-span-2">
                  {contactDetails.map((item) => (
                    <div key={item.name}>
                      <h3 className="text-lg font-medium text-warm-gray-900">
                        {item.name}
                      </h3>
                      <dl className="mt-2 text-base text-warm-gray-500">
                        <div>
                          <dt className="sr-only">
                            <Trans>Email</Trans>
                          </dt>
                          <dd>{item.email}</dd>
                        </div>
                        <div className="mt-1">
                          <dt className="sr-only">
                            <Trans>Phone number</Trans>
                          </dt>
                          <dd>{item.telephone}</dd>
                        </div>
                      </dl>
                    </div>
                  ))}
                </div>
              </section>
              <section
                className="mt-16 pt-16 lg:grid lg:grid-cols-3 lg:gap-8"
                aria-labelledby="location-heading"
              >
                <h2
                  id="location-heading"
                  className="text-2xl font-extrabold text-warm-gray-900 sm:text-3xl"
                >
                  <Trans>Locations</Trans>
                </h2>
                <div className="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:mt-0 lg:col-span-2">
                  {locations.map((location) => (
                    <div key={location.city}>
                      <h3 className="text-lg font-medium text-warm-gray-900">
                        {location.city}
                      </h3>
                      <div className="mt-2 text-base text-warm-gray-500 space-y-1">
                        <p key={location.address}>{location.address}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </section>
            </div>
          </div>
        </Container>
      </>
    </Layout>
  );
};

export default ContactsPage;
